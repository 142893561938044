import React from "react";
import "./hero.scss";
function Hero() {
  return (
    <div className="hero-container">
      <h3>Trading as a Service on Kubernetes Cloud</h3>
      <p>Fully Automated NoOPs Freqtrading in the Cloud</p>
      <a href="#contact">Apply via Telegram</a>
    </div>
  )
}

export default Hero;
