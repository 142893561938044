import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Layout from './components/layout/Layout';
import Header from './components/header/Header';

function App() {
  return (
    <>
      <Header/>
      <Layout/>
    </>
  );
}

export default App;
