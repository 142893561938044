import "./header.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const rootUrl = window.location.origin;

  return (
    <header className="nav_style">
      <div>
        <h3 className="logo">
        <span className="av-link, nav-link-header">FREQ</span><a className="nav-link, nav-link-header" href={rootUrl}>trading<span className="black-color">aas</span></a>
        </h3>
      </div>
      <nav className="navbar">
        <ul className={"nav-list" + (showMenu ? " show" : "")}>
        <li>
            <a href="https://filekeys.com/dsTradingBalanceRealtime.html" target="_blank"  className="nav-link-small">
              Bots&nbsp;Performance&nbsp;Report&nbsp;with&nbsp;History
            </a>
          </li>          
        <li>
            <a href="https://t.me/+MVZd1lTglMQ2YWMx" target="_blank"  className="nav-link-small">
              Join&nbsp;Our&nbsp;Bots&nbsp;on&nbsp;Telegram
            </a>
          </li>          
        <li>
            <a href="https://github.com/DerSalvador/freqtrade-k8s-helm-chart" target="_blank" className="nav-link-small">
              Tradingaas&nbsp;on&nbsp;Github 
            </a>
          </li>
          <li>
            <a href="#termsandconditions" className="nav-link-small">
              Terms&nbsp;and&nbsp;Conditions
            </a>
          </li>
          <li>
            <a href="#theService" className="nav-link-small">
              The&nbsp;Service
            </a>
          </li>
          <li>
            <a href="#whyKubernetes" className="nav-link-small">
              Why&nbsp;Kubernetes&nbsp;Cloud
            </a>
          </li>
          <li>
            <a href="#contact" className="nav-link apply-button">
              Apply&nbsp;via&nbsp;Telegram
            </a>
          </li>
        </ul>
      </nav>
      <button className="hamburger" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars  as IconProp} />
      </button>
    </header>
  );
}


export default Header;
