import React from "react";
import "./termsandconditions.scss";
function termsandconditions() {
  return (
<section className="termsandconditions-container" id="termsandconditions">
        <h3 className="section-title">Terms, Conditions and Disclaimer</h3>

        <div className="card-container">
          <div className="offer-card">
            <ul>
            <li>
              Please read these terms and conditions carefully before using our trading platform. By accessing or using our platform, you agree to be bound by these terms and conditions. 
              If you do not agree with any part of these terms and conditions, please refrain from using our platform.
              </li>
              <li>   
              Trading as a Service makes use of the open source crypto trading solution <a href="https://github.com/freqtrade/freqtrade#GPL-3.0-1-ov-file" target="_blank">FREQTRADE</a> and <a href="https://cloud.google.com/kubernetes-engine?hl=en" target="_blank"> Google Kubernetes Engine on Google Cloud</a>.<br/>Please study all terms, conditions and disclaimer 
              of <a href="https://github.com/freqtrade/freqtrade#GPL-3.0-1-ov-file" target="_blank">FREQTRADE</a> and <a href="https://cloud.google.com/kubernetes-engine?hl=en" target="_blank">Google Cloud</a> which all apply also to all services offered by tradingaas.  

              </li>
              <li>
No Investment Advice: The information provided on our trading platform is for general informational purposes only and should not be considered as financial or investment advice. We do not recommend or endorse any specific investments, financial products, or trading strategies. Users should conduct their own research and consult with a qualified financial advisor before making any investment decisions.
</li>
<li>
Risk Disclosure: Trading involves substantial risk and may not be suitable for all investors. Past performance is not indicative of future results. The user acknowledges and accepts the inherent risks associated with trading and understands that they are solely responsible for any trading decisions and their consequences.
</li>
<li>
No Guarantees: We make no guarantees or representations regarding the accuracy, completeness, or availability of any information provided on our platform. We strive to provide reliable and up-to-date information but disclaim any warranties, express or implied, regarding the accuracy or reliability of such information.
</li>
<li>
No Liability: We shall not be liable for any potential losses, damages, or expenses incurred as a result of using our trading platform. Users accept all risks associated with the use of our platform, including but not limited to trading losses, technical issues, and system failures.
</li>
<li>
Independent Verification: Users are responsible for independently verifying the accuracy, completeness, and suitability of any information provided on our platform. We do not warrant the reliability or accuracy of third-party websites or sources linked to or referenced on our platform.
</li>
<li>
User Obligations: Users agree to use our trading platform in compliance with all applicable laws and regulations. Users shall not engage in any illegal activities, including but not limited to fraud, market manipulation, or unauthorized access to the platform.
</li>
<li>
Intellectual Property: All intellectual property rights, including but not limited to trademarks, logos, and content, on our trading platform are the property of their respective owners. Users agree not to use, reproduce, or distribute any of our intellectual property without prior written consent.
</li>
<li>
Privacy: We value user privacy and handle personal information in accordance with applicable international data protection laws.
</li>
<li>
Modification of Terms: We reserve the right to modify, amend, or update these terms and conditions at any time without prior notice. Users are responsible for regularly reviewing these terms and conditions for any changes. Continued use of our platform after any modifications constitutes acceptance of the updated terms and conditions.
</li>     
<li>
Governing Law and Jurisdiction: These terms and conditions shall be governed by and construed in accordance with the laws of Switzerland. 
Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Switzerland.
</li>     

</ul>
          </div>
        </div>

      </section>
  )
}

export default termsandconditions;
